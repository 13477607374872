<template>
  <div class="date-overrides">
    <button
      class="special button small"
      @click.prevent.stop="showDateOverride()">Add date override</button>
    <hr>
    <div class="overrides">
      <div
        class="field"
        v-for="(over, index) in overrides"
        :key="index">
        <a @click.prevent.stop="showDateOverride(index)">
          <div class="row">
            <div class="col-7 date">
              <p>{{ over.start | moment('DD MMM') }} - {{ over.end | moment('DD MMM YYYY') }}</p>
            </div>
            <div class="col-5">
              <p
                class="time"
                v-for="(hour, index) in over.hours"
                :key="index">
              {{ hour.start | trimSecondsFromTime }} - {{ hour.end | trimSecondsFromTime }}</p>
              <a class="remove">
                <b-icon
                  icon="trash"
                  aria-hidden="true"
                  @click.prevent.stop="deleteDateOverride(index)"></b-icon>
              </a>
            </div>
            <hr>
          </div>
        </a>
      </div>
    </div>
    <date-override-modal
      :dateOverride="dateOverride"
      @onAdd="onAddDateOverride"></date-override-modal>
  </div>
</template>

<script>
import DateOverrideModal from './DateOverrideModal.vue';

export default {
  name: 'DateOverrides',
  components: {
    DateOverrideModal,
  },
  props: {
    overrides: {
      type: Array,
      required: false,
    },
  },
  data() {
    return {
      dateOverride: this.defaultDateOverride,
      index: false,
    };
  },
  methods: {
    onAddDateOverride({ override }) {
      this.$emit('onAdd', { index: this.index, override });
    },
    deleteDateOverride(index) {
      this.$emit('onDelete', { index });
    },
    showDateOverride(index) {
      this.index = index;
      this.dateOverride = this.getCurrentOverrideCopy();
      this.$bvModal.show('modal-date-override');
    },
    getCurrentOverrideCopy() {
      if (this.index === undefined) {
        return this.defaultDateOverride;
      }

      const cloned = { ...this.overrides[this.index] };
      cloned.hours = [...cloned.hours];
      return cloned;
    },
  },
  computed: {
    defaultDateOverride() {
      return {
        start: false,
        end: false,
        hours: [
          {
            start: '09:00:00',
            end: '17:00:00',
          },
        ],
      };
    },
  },
};
</script>

<style scoped>
.field:last-child hr {
  display: none;
}

.field .time {
  margin-bottom: 5px;
}

.field .remove {
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
}

.field .date {
  font-size: 1.1em;
}
</style>
