<template>
  <div class="time-container">
    <b-form-select
      v-model="hours.start"
      @change="onChange">
      <option
        v-for="time in startTimes"
        :key="time"
        :value="time + ':00'">{{ time }}</option>
    </b-form-select> -
    <b-form-select
      v-model="hours.end"
      @change="onChange">
      <option
        v-for="time in endTimes"
        :key="time"
        :value="time + ':00'">{{ time }}</option>
    </b-form-select>
  </div>
</template>

<script>
import Vue from 'vue';

export default {
  name: 'SelectWorkingHours',
  props: {
    hours: {
      type: Object,
      required: false,
      default() {
        return {
          start: false,
          end: false,
        };
      },
    },
  },
  methods: {
    onChange() {
      this.$emit('onUpdate', { hour: this.hours });
    },
    getTimes(start = false, end = false) {
      let date = start || Vue.moment('00:15', 'HH:mm');
      const last = end || Vue.moment('23:59', 'HH:mm');
      const times = [];
      while (date < last) {
        times.push(date.format('HH:mm'));
        date = date.add(15, 'minutes');
      }
      return times;
    },
  },
  computed: {
    startTimes() {
      const end = this.hours.end ? Vue.moment(this.hours.end, 'HH:mm') : false;
      return this.getTimes(false, end);
    },
    endTimes() {
      const start = this.hours.start ? Vue.moment(this.hours.start, 'HH:mm').add(15, 'minutes') : false;
      return this.getTimes(start);
    },
  },
};
</script>

<style scoped>
.time-container {
  width: fit-content;
  display: inline-flex;
  align-items: baseline;
}
</style>
