<template>
  <div class="times-container">
    <p class="unavailable" v-show="workingHours.length === 0">Unavailable</p>
    <div
      class="working-hours"
      v-for="(hours, index) in workingHours"
      :key="index">
      <select-working-hours
        :hours="hours"
        @onUpdate="onUpdateWorkingHours"
      ></select-working-hours>
      <a class="remove">
        <b-icon
          icon="trash"
          aria-hidden="true"
          @click="removeWorkingHours(index)"></b-icon>
      </a>
    </div>
    <a class="add">
      <b-icon
        icon="plus"
        aria-hidden="true"
        @click="addWorkingHours"></b-icon>
    </a>
  </div>
</template>

<script>
import Vue from 'vue';
import SelectWorkingHours from './SelectWorkingHours.vue';

export default {
  name: 'WorkingHours',
  props: {
    workingHours: {
      type: Array,
      required: false,
    },
    identifier: {
      type: String,
      required: false,
    },
  },
  components: {
    SelectWorkingHours,
  },
  methods: {
    onUpdateWorkingHours({ hour }) {
      this.$emit('onUpdate', { hour, identifier: this.identifier });
    },
    addWorkingHours() {
      const hour = this.getNewWorkingHours();
      this.$emit('onAdd', { hour, identifier: this.identifier });
    },
    removeWorkingHours(index) {
      this.$emit('onDelete', { index, identifier: this.identifier });
    },
    getNewWorkingHours() {
      const hour = {
        start: '09:00:00',
        end: '17:00:00',
        weekday: this.identifier,
      };
      if (this.workingHours && this.workingHours.length > 0) {
        const last = this.workingHours[this.workingHours.length - 1];
        hour.start = Vue.moment(last.end, 'HH:mm:ss').add(1, 'hours').format('HH:mm:ss');
        hour.end = Vue.moment(hour.start, 'HH:mm:ss').add(1, 'hours').format('HH:mm:ss');
      }
      return hour;
    },
  },
};
</script>

<style scoped>
.add {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 2em;
}

.add,
.remove {
  cursor: pointer;
}

.working-hours {
  margin-top: 15px;
}

.unavailable {
  font-style: italic;
  margin: 0;
}

.times-container {
  position: relative;
}
</style>
