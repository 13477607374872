<template>
  <div class="availability page container">
    <h2>Availability</h2>
    <div class="row">
      <div class="col">
        <div v-show="error" class="alert alert-danger">{{ error }}</div>
      </div>
    </div>
    <div class="row">
      <div class="default col-md-7">
        <h4>Set your weekly hours</h4>
        <b-form>
          <b-form-group
            class="field"
            v-for="(weekHours, key) in hours"
            :key="key">
            <div class="row">
              <div class="col">
                <b-form-checkbox
                  class="checkbox"
                  v-model="enabled[key]"
                  @change="onUpdateWeekdayStatus(key)">{{key | capitalize}}</b-form-checkbox>
              </div>
              <div class="col">
                <working-hours
                  :workingHours="weekHours"
                  :identifier="key"
                  @onAdd="onAddWorkingHours"
                  @onUpdate="onUpdateWorkingHours"
                  @onDelete="onDeleteWorkingHours"></working-hours>
              </div>
            </div>
            <hr>
          </b-form-group>
        </b-form>
      </div>
      <div class="date-overrides col-md-5">
        <h4>Date overrides</h4>
        <b-form>
          <date-overrides
            :overrides="dateOverrides"
            @onAdd="onAddDateOverride"
            @onDelete="onDeleteDateOverride"></date-overrides>
        </b-form>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import TeacherAvailabilityApi from '../../../api/TeacherAvailability';
import WorkingHours from '../../../components/teacher/availability/WorkingHours.vue';
import DateOverrides from '../../../components/teacher/availability/DateOverrides.vue';

export default {
  name: 'Availability',
  components: {
    WorkingHours,
    DateOverrides,
  },
  data() {
    return {
      hours: {
        mon: [],
        tue: [],
        wed: [],
        thu: [],
        fri: [],
        sat: [],
        sun: [],
      },
      enabled: {
        mon: false,
        tue: false,
        wed: false,
        thu: false,
        fri: false,
        sat: false,
        sun: false,
      },
      dateOverrides: [],
      error: false,
    };
  },
  methods: {
    onAddWorkingHours({ hour, identifier }) {
      this.hours[identifier] = this.insertHour(hour, this.hours[identifier]);
      this.enabled[identifier] = true;
      this.error = '';
      TeacherAvailabilityApi
        .addWorkingHours(hour)
        .then(({ data }) => {
          const index = this.hours[identifier].indexOf(hour);
          this.hours[identifier][index] = data;
        })
        .catch((error) => {
          this.error = this.parseError(error, 'Unable to add working hours');
        });
    },
    onUpdateWeekdayStatus(weekday) {
      this.error = '';
      TeacherAvailabilityApi
        .updateWeekdayStatus({ weekday, value: this.enabled[weekday] })
        .then(({ data }) => {
          this.hours[weekday] = [];
          if (data) {
            this.hours[weekday].push(data);
          }
        })
        .catch((error) => {
          this.error = this.parseError(error, 'Unable to update weekday status');
        });
    },
    onUpdateWorkingHours({ hour, identifier }) {
      this.error = '';
      const index = this.hours[identifier].indexOf(hour);
      Object.assign(this.hours[identifier][index], hour);
      TeacherAvailabilityApi
        .updateWorkingHours(hour)
        .catch((error) => {
          this.error = this.parseError(error, 'Unable to update working hours');
        });
    },
    onDeleteWorkingHours({ index, identifier }) {
      this.error = '';
      const hour = this.hours[identifier][index];
      if (hour.id) {
        TeacherAvailabilityApi
          .deleteWorkingHours(this.hours[identifier][index])
          .catch((error) => {
            this.error = this.parseError(error, 'Unable to delete working hours');
          });
      }

      this.$delete(this.hours[identifier], index);
      if (this.hours[identifier].length === 0) {
        this.enabled[identifier] = false;
      }
    },
    onAddDateOverride({ override, index }) {
      if (index !== undefined) {
        Object.assign(this.dateOverrides[index], override);
      } else {
        this.dateOverrides = this.insertHour(override, this.dateOverrides);
      }
      this.error = '';
      TeacherAvailabilityApi
        .addOrUpdateDateOverride(override)
        .then(({ data }) => {
          this.dateOverrides[index] = data;
        })
        .catch((error) => {
          this.error = this.parseError(error, 'Unable to add/update date override');
        });
    },
    onDeleteDateOverride({ index }) {
      this.error = '';
      TeacherAvailabilityApi
        .deleteDateOverride(this.dateOverrides[index])
        .catch((error) => {
          this.error = this.parseError(error, 'Unable to delete date override');
        });
      this.$delete(this.dateOverrides, index);
    },
    insertHour(hour, allHours) {
      const start = Vue.moment(hour.start, 'HH:mm');
      const array = allHours || [];
      for (let i = 0; i < array.length; i += 1) {
        const h = array[i];
        if (Vue.moment(h.start, 'HH:mm') > start) {
          array.splice(i, 0, hour);
          return array;
        }
      }

      array.push(hour);
      return array;
    },
    getDefaultTimes() {
      TeacherAvailabilityApi
        .get()
        .then(({ data }) => {
          // Setup hours
          for (let i = 0; i < data.hours.length; i += 1) {
            const hour = data.hours[i];
            this.hours[hour.weekday].push(hour);
          }

          this.enabled = data.enabled;
          this.dateOverrides = data.date_overrides;
        })
        .catch((error) => {
          this.error = this.parseError(error, 'Unable to retrieve the teacher availability');
        });
    },
  },
  created() {
    this.getDefaultTimes();
  },
};
</script>

<style scoped>
.field:last-child hr {
  display: none;
}

.field .checkbox {
  padding: 0;
  width: fit-content;
  display: inline-flex;
}
</style>

<style>
.field .time-container select {
  margin-top: 0 !important;
  margin-right: 15px;
}

.field .time-container select:last-child {
  margin-left: 15px;
}

.field .checkbox .custom-control-label:before {
  width: 1.5em;
  height: 1.5em;
  line-height: 1.4em;
}

.field .checkbox .custom-control-label {
  line-height: 1.6em;
  font-weight: bold;
}

</style>
