<template>
  <b-modal
    id="modal-date-override"
    title="Select the date(s) you want to assign specific hours"
    hide-footer
    size="md">
    <div class="content">
      <v-date-picker
        :attributes="attrs"
        :min-date="new Date()"
        is-range
        is-expanded
        v-model="override"
        color="gray" />
      <div class="available-times">
        <h3>What hours are you available?</h3>
        <working-hours
          :workingHours="override.hours"
          @onAdd="onAddWorkingHours"
          @onDelete="onDeleteWorkingHours"></working-hours>
      </div>
    </div>
    <footer class="modal-footer">
      <button
        class="button small"
        @click.prevent.stop="onCancel">Cancel</button>
      <button
        class="button special small"
        @click.prevent.stop="onAdd">Add</button>
    </footer>
  </b-modal>
</template>

<script>
import Vue from 'vue';
import WorkingHours from './WorkingHours.vue';

export default {
  name: 'DateOverrideModal',
  components: {
    WorkingHours,
  },
  props: {
    dateOverride: {
      type: Object,
      required: false,
    },
  },
  methods: {
    onCancel() {
      this.$emit('onCancel');
      this.$bvModal.hide('modal-date-override');
    },
    onAdd() {
      this.dateOverride.start = Vue.moment(this.dateOverride.start).format('YYYY-MM-DD');
      this.dateOverride.end = Vue.moment(this.dateOverride.end).format('YYYY-MM-DD');
      this.$emit('onAdd', { override: this.dateOverride });
      this.$bvModal.hide('modal-date-override');
    },
    onAddWorkingHours({ hour }) {
      this.dateOverride.hours.push(hour);
    },
    onDeleteWorkingHours({ index }) {
      this.$delete(this.dateOverride.hours, index);
    },
  },
  computed: {
    override: {
      get() {
        return this.dateOverride ?? {};
      },
      set(override) {
        this.dateOverride.start = override.start;
        this.dateOverride.end = override.end;
      },
    },
    attrs() {
      const dates = [];
      if (this.override.start && this.override.end) {
        dates.push(Vue.moment(this.override.start).toDate());
        dates.push(Vue.moment(this.override.end).toDate());
      }

      return [
        {
          key: 'selected',
          dates,
        },
      ];
    },
  },
};
</script>

<style scoped>
  .available-times h3,
  .repeat h3 {
    text-transform: none;
    text-align: center;
    font-size: 18px;
    margin-top: 15px;
    margin-bottom: 10px;
  }

  .repeat > select {
    border: solid 1px rgba(144, 144, 144, 0.25);
    margin-bottom: 15px;
  }

  .repeat > .date-descriptions {
    padding-left: 25px;
  }

  input[type="radio"]:checked + label:before {
    background: #f32853;
    border-color: #f32853;
  }

  .available-times ul {
    list-style: none;
    font-family: sans-serif;
    padding: 0;
    margin-left: -5px;
    margin-bottom: 15px;
  }

  .available-times ul li {
    display: inline-block;
    text-align: center;
    width: 100px;
    padding: 0;
    color: #f32853;
    border-color: #f32853;
    border-radius: 5px;
    border: 1px solid;
    margin-left: 5px;
    margin-top: 5px;
  }

  .available-times ul .no-available-times {
    text-align: center;
  }

  .available-times ul li:hover,
  .available-times ul li.selected {
    cursor: pointer;
    color: #fff;
    background-color: #f32853;
  }

  footer {
    margin-top: 15px;
  }
</style>

<style>
  .vc-highlights.vc-day-layer .vc-day-layer
    .vc-highlight:not(
      .vc-highlight-base-end
    ):not(
      .vc-highlight-base-middle
    ):not(
      .vc-highlight-base-start
    ) {
    background-color: #f32853 !important;
    color: #f32853;
    border-color: #f32853;
    border-radius: 5px !important;
    border: 1px solid !important;
  }

  .vc-title,
  .vc-day-content {
    color: #484848;
  }

  .vc-day-content:hover,
  .vc-day-content:focus {
    border-radius: 5px;
  }
</style>
