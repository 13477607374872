/**
 * Holds all the user API requests
 */
import Api from './Api';

const END_POINT = 'teacher/availability';

export default {
  get(spinner = true) {
    return Api.get(`${END_POINT}`, null, { spinner });
  },
  addWorkingHours(hour, spinner = true) {
    return Api.post(`${END_POINT}/working-hours`, hour, { spinner });
  },
  updateWorkingHours(hour, spinner = true) {
    return Api.put(`${END_POINT}/working-hours`, hour, { spinner });
  },
  deleteWorkingHours({ id }, spinner = true) {
    return Api.delete(`${END_POINT}/working-hours/${id}`, null, { spinner });
  },
  addOrUpdateDateOverride(override, spinner = true) {
    return Api.post(`${END_POINT}/date-override`, override, { spinner });
  },
  deleteDateOverride({ id }, spinner = true) {
    return Api.delete(`${END_POINT}/date-override/${id}`, null, { spinner });
  },
  updateWeekdayStatus(weekdayStatus, spinner = true) {
    return Api.put(`${END_POINT}/weekday`, weekdayStatus, { spinner });
  },
};
